import React from 'react'

function AdminBooking() {
    return (
        <div>
            Book admin
        </div>
    )
}

export default AdminBooking
